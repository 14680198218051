import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { VisitorEntities } from 'client/data/models/visitor';
import { CHART_PROPS_REDESIGN } from 'site-modules/shared/constants/price-checker';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { getEspTrustDateText } from 'site-modules/shared/utils/esp';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { OdometerPaymentValue } from 'site-modules/shared/components/car-buying/odometer-payment-value/odometer-payment-value';
import { PricingChart } from './pricing-chart';

import './price-checker-promo-chart.scss';

function PriceCheckerPromoChartUI({
  tmvChartData,
  className,
  isMobile,
  columnWidth,
  columnPadding,
  animationBegin,
  idPrefix,
  hidePriceLabels,
  priceLabelClassName,
  priceClassName,
  showEspTrustDate,
}) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const currentTmvChartData = animate && tmvChartData;

  const customPriceLabels = currentTmvChartData
    ? [
        {
          label: formatPriceString(currentTmvChartData.maxGreatPrice),
          index: CHART_PROPS_REDESIGN.COMMON.keyPointsIndex.lowEnd,
        },
        {
          label: formatPriceString(currentTmvChartData.maxFairPrice),
          index: CHART_PROPS_REDESIGN.COMMON.keyPointsIndex.highEnd,
          end: true,
        },
      ]
    : [
        { index: CHART_PROPS_REDESIGN.COMMON.keyPointsIndex.lowEnd },
        { index: CHART_PROPS_REDESIGN.COMMON.keyPointsIndex.highEnd, end: true },
      ];

  return (
    <div className={classnames('price-checker-promo-chart text-center w-100', className)}>
      <div className="chart-heading">
        {currentTmvChartData ? (
          <Fragment>
            {showEspTrustDate && (
              <div className="small font-italic text-cool-gray-40 mb-0_5">{getEspTrustDateText()}</div>
            )}
            <span className={priceLabelClassName}>{currentTmvChartData.dealerPrice.cta}</span>
            {currentTmvChartData.dealerPrice.tooltip && (
              <TooltipItem
                id={currentTmvChartData.dealerPrice.label}
                item={{
                  placement: 'bottom',
                  text: currentTmvChartData.dealerPrice.tooltip,
                }}
                iconClassName="text-primary-darker ml-0_25"
                innerClassName="p-1"
              />
            )}
          </Fragment>
        ) : (
          <div className="heading-4">Check your price quote</div>
        )}
        {/* 'd-none' to have animation on first input */}
        <div
          className={classnames('user-price', priceClassName, {
            'd-none': !currentTmvChartData,
            'd-flex align-items-center justify-content-center': currentTmvChartData,
          })}
          style={{ color: get(currentTmvChartData, 'dealerPrice.color') }}
        >
          {get(currentTmvChartData, 'dealerPrice.label')} Price:{' '}
          <OdometerPaymentValue
            value={get(currentTmvChartData, 'maxGoodPrice')}
            className="ml-0_25 col-auto"
            valueClassName="align-top"
          />
        </div>
      </div>
      <PricingChart
        {...CHART_PROPS_REDESIGN.COMMON}
        columnWidth={columnWidth || CHART_PROPS_REDESIGN.COMMON.columnWidth}
        columnPadding={columnPadding || CHART_PROPS_REDESIGN.COMMON.columnPadding}
        dataPoints={
          currentTmvChartData
            ? CHART_PROPS_REDESIGN.DATA_POINTS.WITH_DATA
            : CHART_PROPS_REDESIGN.DATA_POINTS.WITHOUT_DATA
        }
        customPriceLabels={hidePriceLabels ? null : customPriceLabels}
        selectedPriceIndex={currentTmvChartData ? 6.8 : undefined}
        isMobile={isMobile}
        hasAnimation
        animationBegin={animationBegin}
        idPrefix={idPrefix}
        isSample
      />
    </div>
  );
}

PriceCheckerPromoChartUI.propTypes = {
  className: PropTypes.string,
  tmvChartData: PropTypes.shape({}),
  visitorLocation: VisitorEntities.Location,
  isMobile: PropTypes.bool,
  animationBegin: PropTypes.string,
  columnWidth: PropTypes.shape({
    desktop: PropTypes.number.isRequired,
    mobile: PropTypes.number.isRequired,
  }),
  columnPadding: PropTypes.number,
  priceLabelClassName: PropTypes.string,
  priceClassName: PropTypes.string,
  idPrefix: PropTypes.string,
  hidePriceLabels: PropTypes.bool,
  showEspTrustDate: PropTypes.bool,
};

PriceCheckerPromoChartUI.defaultProps = {
  className: null,
  tmvChartData: null,
  visitorLocation: null,
  isMobile: false,
  animationBegin: undefined,
  columnWidth: null,
  columnPadding: null,
  priceLabelClassName: 'heading-6 mb-0_25',
  priceClassName: 'heading-4',
  idPrefix: '',
  hidePriceLabels: false,
  showEspTrustDate: true,
};

const mapStateToProps = state => ({
  visitorLocation: get(state, 'visitor.location', {}),
});

export const PriceCheckerPromoChart = connect(mapStateToProps)(PriceCheckerPromoChartUI);
