import React from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VehicleDetailsModel, VehicleQuestionsEntities, buildQuestionsPath } from 'client/data/models/mmy-details';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { getTCOUrl } from 'client/site-modules/shared/utils/core-link-constructor';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { Link } from 'site-modules/shared/components/link/link';

import './vehicle-questions.scss';

function getQuestionByKey(vehicle, key) {
  const questions = {
    'good-car': `Is the ${vehicle.make} ${vehicle.model} a good car?`,
    mpg: `Does the ${vehicle.make} ${vehicle.model} have good MPG?`,
    resale: `Does the ${vehicle.make} ${vehicle.model} have good resale value?`,
  };

  return questions[key];
}

function getContextualLinksByKey(params, vehicle, key) {
  const mmy = {
    makeSlug: params.make,
    modelSlug: params.model,
    year: params.year,
    style: vehicle.style,
  };

  const links = {
    'good-car': null,
    mpg: null,
    resale: { linkText: 'True Cost to Own calculator', href: getTCOUrl(mmy) },
  };

  return (
    links[key] && (
      <Link to={links[key].href} className="text-primary-darker mt-1 d-inline-block">
        {links[key].linkText}
        <i className="icon-arrow-right3 pl-0_25 size-10" aria-hidden />
      </Link>
    )
  );
}

function VehicleQuestionsUI({ params, vehicleQuestions }) {
  if (
    !vehicleQuestions ||
    !params ||
    !Object.keys(params).length ||
    !Object.keys(vehicleQuestions).length ||
    !vehicleQuestions.questions ||
    !vehicleQuestions.questions.length
  ) {
    return null;
  }

  return (
    <div className="mb-2">
      <h2 className="display-2 size-24 text-left mb-1_5 font-weight-bold text-gray-darker">FAQs</h2>
      {vehicleQuestions.questions.map((question, index) => {
        const key = index + 1;
        return (
          question.content &&
          question.id !== 'good-deal' && (
            <Row key={key} className="vehicle-questions text-gray-darker mb-1_5">
              <Col xs={12}>
                <p className="size-16 mb-0 font-weight-bold">
                  <span className="question">{getQuestionByKey(vehicleQuestions.vehicle, question.id)}</span>
                </p>
              </Col>
              <Col xs={12}>
                <ContentFragment>{question.content}</ContentFragment>
                {getContextualLinksByKey(params, vehicleQuestions.vehicle, question.id)}
              </Col>
            </Row>
          )
        );
      })}
      <hr className="m-0" />
    </div>
  );
}

VehicleQuestionsUI.propTypes = {
  vehicleQuestions: VehicleQuestionsEntities.QuestionsContent,
  params: PropTypes.shape({
    make: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.string,
  }),
};

VehicleQuestionsUI.defaultProps = {
  vehicleQuestions: null,
  params: null,
};

export const VehicleQuestions = connectToModel(VehicleQuestionsUI, {
  vehicleQuestions: bindToPath(({ params }) => buildQuestionsPath(params), VehicleDetailsModel),
});

VehicleQuestions.propTypes = {
  params: CorePageParams,
};

VehicleQuestions.defaultProps = {
  params: null,
};
