import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { EditorialReviewEntities } from 'client/data/models/editorial-review';
import { SafetyEntities, buildSafetyRatingsPathByParams, SafetyModel } from 'client/data/models/safety';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { buildMMYGenericFeaturesPath, VehicleEntities, VehicleModel } from 'client/data/models/vehicle-v2';
import { safetyMap } from 'client/data/models/safety-map';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { isNhtsaRatingsEmpty } from './utils';
import { formatNhtsaRatingItem } from './safety-nhtsa-ratings';

const FEATURES_PRIORITY = [
  'back-up-camera',
  'blind-spot-monitoring',
  'automatic-emergency-braking',
  'lane-departure-warning',
  'parking-sensors',
  '360-degree-camera',
  'alarm',
  'tire-pressure-warning',
  'stability-control',
  'pre-collision-safety-system',
  'heads-up-display',
  'occupant-sensing-airbag',
  'post-collision-safety-system',
];
const MAX_FEATURES_NUM = 10;

function SeoSafetyUI({ modelName, ratings, editorialReview, safetyGenericFeatures, params, isMobile }) {
  const nhtsaRatings = get(ratings, 'nhtsaRatings');
  const iihsRatings = get(ratings, 'iihsRatings');
  const safetyFeatures = get(editorialReview, 'safetyFeatures');
  const overall = get(nhtsaRatings, 'overall');

  const displayFeatures =
    Array.isArray(safetyGenericFeatures) &&
    safetyGenericFeatures
      .sort(
        ({ niceId: niceIdA }, { niceId: niceIdB }) =>
          FEATURES_PRIORITY.indexOf(niceIdA) - FEATURES_PRIORITY.indexOf(niceIdB)
      )
      .slice(0, MAX_FEATURES_NUM);

  if (
    isEmpty(safetyFeatures) &&
    isEmpty(safetyGenericFeatures) &&
    isEmpty(iihsRatings) &&
    isNhtsaRatingsEmpty(nhtsaRatings)
  ) {
    return null;
  }

  return (
    <section className="core-safety" data-tracking-parent="edm-entry-safety">
      <h2 className="text-left size-24 font-weight-bold mb-0_5">Safety</h2>
      {!isEmpty(safetyFeatures) && (
        <Fragment>
          <h3 className="h4 font-weight-normal mb-1_5 text-left size-16">
            {params.isUsed
              ? `Our experts like the ${modelName} models:`
              : `Our experts’ favorite ${modelName} safety features:`}
          </h3>
          <dl className="mb-0">
            {safetyFeatures.map(({ title, description }) => (
              <Fragment key={title}>
                <dt>{title}</dt>
                <dd className="mb-1_5">{description}</dd>
              </Fragment>
            ))}
          </dl>
        </Fragment>
      )}
      {isEmpty(safetyFeatures) && !!get(displayFeatures, 'length') && (
        <Fragment>
          <h3 className="h4 font-weight-normal mb-1_5 text-left size-16">
            Key safety features on the {modelName} include:
          </h3>
          <ul>
            {displayFeatures.map(({ niceId, displayName }) => (
              <li key={niceId}>{displayName}</li>
            ))}
          </ul>
        </Fragment>
      )}
      <ContentCollapse
        minHeight={`${isMobile ? 80 : 52}px`}
        textClosed="See full safety scores"
        textOpen="See less"
        isEclipseFade={false}
        classes="safety-ratings-collapse"
        btnClasses="safety-ratings-collapse-btn size-16 text-primary-darker px-0_5 pt-0_5 pb-0 text-transform-none"
        btnTextClasses="font-weight-normal"
        btnContainerClasses="p-0"
        showBtnBelow
        justify=""
      >
        {!isNhtsaRatingsEmpty(nhtsaRatings) && (
          <Fragment>
            <h3 className="d-inline-block size-16 mb-0_5 font-weight-bold">
              NHTSA Overall Rating {!!overall && `${overall} out of 5 stars`}
            </h3>
            <p className="size-16 mb-0_5">
              The National Highway Transportation Safety Administration offers independent analysis.
            </p>
            <table cellSpacing="10px" cellPadding="10px" border="1px">
              <tbody>
                {Object.keys(safetyMap.nhtsaRatings).map(ratingGroupKey => {
                  const ratingGroup = safetyMap.nhtsaRatings[ratingGroupKey];

                  return (
                    <Fragment key={ratingGroupKey}>
                      <tr>
                        <th scope="row">{ratingGroup.name}</th>
                        <th>Rating</th>
                      </tr>
                      {Object.keys(ratingGroup.values).map(ratingItemKey => {
                        const ratingItem = ratingGroup.values[ratingItemKey];
                        const ratingItemValue = get(nhtsaRatings, `${ratingGroupKey}.${ratingItemKey}`);

                        return (
                          <tr key={ratingItemKey}>
                            <td>{ratingItem.name}</td>
                            <td>{formatNhtsaRatingItem(ratingItem.type, ratingItemValue)}</td>
                          </tr>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
            <hr />
          </Fragment>
        )}
        {!isEmpty(iihsRatings) && (
          <Fragment>
            <h3>IIHS Rating</h3>
            <p>The Insurance Institute of Highway Safety uses extensive crash tests to determine car safety.</p>
            <table cellSpacing="10px" cellPadding="10px" border="1px">
              <tbody>
                {Object.keys(safetyMap.iihsRatings).map(ratingKey => {
                  const ratingItemValue = iihsRatings[ratingKey];

                  return (
                    <tr key={ratingKey}>
                      <th scope="row">{safetyMap.iihsRatings[ratingKey]}</th>
                      <td>{ratingItemValue ? <div>{ratingItemValue}</div> : <span>{'Not Tested'}</span>}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <hr />
          </Fragment>
        )}
      </ContentCollapse>
      <hr className="mt-1_5 mb-2" />
    </section>
  );
}

SeoSafetyUI.propTypes = {
  params: CorePageParams.isRequired,
  modelName: PropTypes.string.isRequired,
  editorialReview: EditorialReviewEntities.EditorialReview,
  safetyGenericFeatures: PropTypes.arrayOf(VehicleEntities.GenericFeature),
  ratings: SafetyEntities.Ratings,
  isMobile: PropTypes.bool,
};

SeoSafetyUI.defaultProps = {
  editorialReview: null,
  safetyGenericFeatures: null,
  ratings: null,
  isMobile: false,
};

export const mapStateToProps = ({ mobile }) => ({
  isMobile: mobile,
});

export const SeoSafety = connect(mapStateToProps)(
  connectToModel(SeoSafetyUI, {
    ratings: bindToPath(buildSafetyRatingsPathByParams, SafetyModel),
    safetyGenericFeatures: bindToPath(
      ({ params, editorialReview }) =>
        isEmpty(get(editorialReview, 'safetyFeatures')) && buildMMYGenericFeaturesPath(params),
      VehicleModel,
      genericFeatures => get(genericFeatures, 'Safety', []).filter(({ niceId }) => FEATURES_PRIORITY.includes(niceId))
    ),
  })
);
