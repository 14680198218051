import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
/* MODELS */
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  buildPreProdContentPath,
  buildReviewPath,
  EditorialReviewEntities,
  EditorialReviewModel,
  FirstContentEntity,
} from 'client/data/models/editorial-review';
import { VisitorEntities, VisitorModel } from 'client/data/models/visitor';
import {
  buildLatestVehiclesPath,
  FeatureSpecsPaths,
  VehicleEntities,
  VehicleModel,
} from 'client/data/models/vehicle-v2';
import {
  buildInventoryCountByTypePath,
  InventoryAggregateEntities,
  InventoryAggregateModel,
} from 'client/data/models/inventory-aggregate';
import { SEOModel } from 'client/data/models/seo';
/* UTILS */
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { MinHeightWrapper } from 'site-modules/shared/components/min-height-wrapper/min-height-wrapper';
import { cloneParamsWithPubState, seot3630ChalSubmodelMatch } from 'site-modules/shared/utils/core-page/page-helpers';
import { CorePageParams, getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { buildDisplayVehicle } from 'site-modules/shared/utils/core-page/display-name-builders';
import { getEditorialIcon } from 'site-modules/shared/utils/core-page/rating-and-ranking';
import { getPublicationStateForMakeModel, isPreProd } from 'site-modules/shared/utils/publication-states';
import { isUnknownZip } from 'site-modules/shared/utils/location';
import { isRenderedAdUnit } from 'client/utils/ads';
/* ADS */
import { MrectNative } from 'site-modules/shared/components/native-ad/mrect/mrect-native';
import { NativeVideoMrect } from 'site-modules/shared/components/native-ad/video-mrect/video-mrect-native';
import { BuildPriceAd } from 'site-modules/shared/components/native-ad/buildprice-ad/buildprice-ad';
/* TRACKING */
import { TrackingMap } from 'client/tracking/maps/core-page';
import { TrackingHandler } from 'client/tracking/handler';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
/* UTIL COMPONENTS */
import { HashAnchor } from 'site-modules/shared/components/hash-anchor/hash-anchor';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
/* SHARED COMPONENTS */
import { CoreBreadcrumbs } from 'site-modules/shared/components/core-breadcrumbs/core-breadcrumbs';
import { PriceCheckerPromo } from 'site-modules/core-page/components/price-checker-promo/price-checker-promo';
import { PageLinksWidget } from 'site-modules/shared/components/page-links-widget/page-links-widget';
import { DockedSrpLink } from 'site-modules/shared/components/docked-srp-link/docked-srp-link';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';
import { AppointmentSurveyExperiment } from 'site-modules/core-page/components/appointment-survey/appointment-survey-experiment';
/* COMPONENTS */
import { VehicleQuestions } from 'site-modules/core-page/components/vehicle-questions/vehicle-questions';
import { NewCoreIntro } from 'site-modules/core-page/components/new-core-intro/new-core-intro';
import { VehicleComparisons } from 'site-modules/core-page/components/vehicle-comparisons/vehicle-comparisons';
import { LatestVideoForGenerationModule } from 'site-modules/shared/components/video-module/video-module';
import { FeaturesHighlights } from 'site-modules/core-page/components/features-highlights/features-highlights';
import { RelatedArticles } from 'site-modules/core-page/components/related-articles/related-articles';
import { ShoppingLinks } from 'site-modules/core-page/components/shopping-links/shopping-links';
import { ConsumerReviewsModular } from 'site-modules/shared/components/consumer-reviews-modular/consumer-reviews-modular';
/* CONSTANTS */
import { DEALERLESS_OEM } from 'site-modules/shared/constants/oem';
import { CORE_HEADER_SRC_INLINE } from 'site-modules/core-page/constants/core-page';
import { CORE_PAGE_HEADER } from 'client/images/inline-svgs/core-page/header';
/* CSS */
import 'site-modules/core-page/components/preprod-articles/preprod-articles.scss';
import './new-core.scss';
/* LAZY IMPORTS */
import lazyImports from './lazy-imports';

const { FaqSection, Safety } = lazyImports;

const CONTENT_TRACKING_DATA = {
  action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
  action_name: TrackingConstant.ACTION_SHOW_CONTENT,
  synpartner: TrackingConstant.SYNPARTNER,
  action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
  page_name: 'new_model_core',
  page_category: 'new_model_core',
};

const CORE_6303_MAP = {
  honda: { civic: { '2023': true }, accord: { '2023': true } },
  toyota: { camry: { '2023': true } },
};

function NewCoreUI({
  vehicle,
  editorialReview,
  firstContent,
  visitorLocation,
  params: propsParams,
  styleAttributes,
  isMobile,
  inventoryCount,
  isMinimalViableData,
  isSeot3854Enabled,
  isSeot3630,
  isCore6112,
  isCore6334Enabled,
}) {
  const [isBuildPriceAdVisible, setBuildPriceAdVisible] = useState(true);
  const isTracked = useRef({ edmundsTypeCategories: false, teaser: false, editorialRating: false });

  useEffect(() => {
    TrackingHandler.useMap(TrackingMap);
  }, []);

  const edmundsTypeCategories = get(vehicle, 'edmundsTypeCategories');
  const hasTeaser = !!get(firstContent, 'hasTeaser');
  const hasEditorialReview = !!editorialReview;
  const overallRating = get(editorialReview, 'ratings.overall.rating');
  // content tracking
  useEffect(() => {
    // edmundsTypeCategories
    if (!isTracked.current.edmundsTypeCategories && edmundsTypeCategories) {
      isTracked.current.edmundsTypeCategories = true;

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          ...CONTENT_TRACKING_DATA,
          subaction_name: TrackingConstant.VEHICLE_TYPE,
          value: Object.keys(edmundsTypeCategories).toString(),
        },
      });
    }

    // teaser
    if (!isTracked.current.teaser && hasTeaser) {
      isTracked.current.teaser = true;

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          ...CONTENT_TRACKING_DATA,
          subaction_name: TrackingConstant.SHOW_TEASER,
          value: 'yes',
        },
      });
    }

    // editorial rating
    if (!isTracked.current.editorialRating && hasEditorialReview) {
      isTracked.current.editorialRating = true;

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          ...CONTENT_TRACKING_DATA,
          subaction_name: TrackingConstant.RATING_CATEGORY,
          value: overallRating ? getEditorialIcon(overallRating).text : 'no rating',
        },
      });
    }
  }, [hasEditorialReview, overallRating, hasTeaser, edmundsTypeCategories]);

  const hideEmptyAdContainer = useCallback(slot => setBuildPriceAdVisible(isRenderedAdUnit(slot)), []);

  const getBuildPriceAd = hideSeparator => (
    <Container>
      <Row className="build-price-viewable-parent">
        <Col xs={12}>
          <BuildPriceAd
            mobile={isMobile}
            wrapperClass="core"
            includeMrectAdCall
            slotRenderEndListener={hideEmptyAdContainer}
            position={'0'}
          />
          {!hideSeparator && isBuildPriceAdVisible && <hr className="mt-2 mb-3_5" />}
        </Col>
      </Row>
    </Container>
  );

  if (!(vehicle && vehicle.make && vehicle.model && vehicle.submodels && vehicle.year && vehicle.pubStates)) {
    return (
      <main id="main-content" className="core-page loading">
        <Container>Loading&hellip;</Container>
      </main>
    );
  }
  const params = cloneParamsWithPubState({
    params: {
      ...propsParams,
      isMinimalViableData,
      isElectric: !!get(styleAttributes, 'electric'),
      isPluginHybrid: !!get(styleAttributes, 'pluginElectric'),
      isTruck: !!get(styleAttributes, 'truck'),
      isDealerlessOem: DEALERLESS_OEM.includes(vehicle.make.slug),
    },
    year: vehicle.year,
  });

  const displayVehicle = buildDisplayVehicle({
    makeModelSubmodelYear: vehicle,
    params,
    disableTrademarkCharacter: true,
  });
  const {
    model: { name: model },
    pubStates,
  } = vehicle;

  const isPreprod = isPreProd(pubStates);

  const isInternational = isUnknownZip(visitorLocation);
  const noEditorialReview =
    !(get(editorialReview, 'reviewLink') || get(editorialReview, 'hasRelatedArticles')) &&
    !get(firstContent, 'articles.length');

  const isCore6303Enabled = !params.submodel && !!CORE_6303_MAP?.[params.make]?.[params.model]?.[params.year];
  const headerClassName = 'header-background pos-a top-0 left-0 w-100';

  // SEOT-3630 Core Breadcrumbs Test
  const isSeot3630Enabled = isSeot3630;
  const seot3630ChalSubmodelType = seot3630ChalSubmodelMatch(edmundsTypeCategories);

  return (
    <main id="main-content" className="core-page pos-r pt-0_75 pt-md-1_5">
      {isCore6303Enabled ? (
        CORE_PAGE_HEADER[isMobile](headerClassName)
      ) : (
        <img className={headerClassName} src={CORE_HEADER_SRC_INLINE[isMobile]} alt="" fetchpriority="high" />
      )}
      <NewCoreIntro
        params={{ ...params, noEditorialReview }}
        vehicle={vehicle}
        editorialReview={editorialReview}
        firstContent={firstContent}
        visitorLocation={visitorLocation}
        inventoryCount={inventoryCount}
        isMobile={isMobile}
        isPreprod={isPreprod}
        isInternational={isInternational}
        isCore6112Enabled={!isPreprod && isCore6112}
        isCore6334Enabled={!isPreprod && isCore6334Enabled}
      />
      <Container>
        <Row>
          {!isPreprod && (
            <Col xs={12} lg={8}>
              <ScrollElement id="subnav-consumer-reviews">
                <ConsumerReviewsModular
                  className="mb-3_5"
                  vehicle={vehicle}
                  isMobile={isMobile}
                  creativeId="consumer-reviews"
                />
              </ScrollElement>
            </Col>
          )}
          <Col xs={12} lg={isPreprod ? undefined : 4}>
            {!isPreprod && (
              <ShoppingLinks
                className="mb-2 mb-md-0"
                params={params}
                vehicle={vehicle}
                inventoryTotalNumber={inventoryCount?.NEW}
              />
            )}

            {isMobile && getBuildPriceAd(true)}

            {!isMobile && (
              <div className="my-2 adsol-mrect">
                <NativeVideoMrect
                  position={'1'}
                  wiredOnly
                  wiredBreakpoints={{ lg: true, xl: true }}
                  renderOnScroll
                  refreshable={false}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        <ScrollElement id="videos">
          <LatestVideoForGenerationModule
            className="border-0"
            isMobile={isMobile}
            vehicle={vehicle}
            videoLargeColumnSize={8}
            playlistLargeColumnSize={4}
            pageName={`${getPublicationStateForMakeModel(vehicle.pubStates)}_model_core`}
            withSchemaMeta={false}
            lazyLoadPreview
            isModularDesign
          />
        </ScrollElement>
        <hr className="mt-2 mb-3_5" />
      </Container>

      {!isPreprod && (
        <Fragment>
          <ScrollElement tag={Container} id="subnav-features">
            <Row>
              <Col xs={12} lg={8}>
                <FeaturesHighlights params={params} vehicle={vehicle} isMobile={isMobile} />
              </Col>
            </Row>
            <hr className="mt-2 mb-3_5" />
          </ScrollElement>
          <ScrollElement tag={Container} id="subnav-competition">
            <Row>
              <Col xs={12} lg={8}>
                <VehicleComparisons vehicle={vehicle} />
                <hr className="mt-2 mb-3_5" />
              </Col>
            </Row>
          </ScrollElement>
          <Container className="mb-3_5">
            <MinHeightWrapper componentType="PRICE_CHECKER" forceStaticHeight>
              <PriceCheckerPromo vehicle={vehicle} utmCampaign="lower-price-checker" isMobile={isMobile} />
            </MinHeightWrapper>
          </Container>

          {!isMobile && getBuildPriceAd(false)}

          <Container>
            <Row>
              <Col xs={12} className="mb-3_5 mb-lg-0">
                <NativeVideoMrect
                  position={'1'}
                  mobileBreakpoints={{ xs: true, sm: true }}
                  wiredBreakpoints={{ md: true }}
                  renderWhenViewable
                  refreshable={false}
                />
              </Col>
            </Row>
          </Container>

          <ScrollElement tag={Container} id="subnav-safety">
            <Row>
              <Col xs={12} lg={8}>
                <Safety modelName={model} editorialReview={editorialReview} params={params} hasSeparator={false} />
                <hr className="mt-2 mb-3_5" />
              </Col>

              <Col xs={12} lg={4}>
                <Container className="my-md-0_25 pb-md-1_5 mrect adsol-mrect">
                  <MrectNative
                    position={'2'}
                    wiredOnly
                    wiredBreakpoints={{ lg: true, xl: true }}
                    renderOnScroll
                    refreshable={false}
                    renderWhenViewable
                    verticalOffset="100%"
                  />
                </Container>
              </Col>
            </Row>
          </ScrollElement>
        </Fragment>
      )}

      <Container>
        <Row>
          <Col xs={12} lg={8}>
            <VehicleQuestions params={params} />
          </Col>
        </Row>
      </Container>

      {!isSeot3854Enabled && (
        <ScrollElement id="related-articles">
          <Container className="preprod-article related-articles">
            <HashAnchor className="core-page-anchor" name="related-articles" />
            <Row>
              <Col xs={12} lg={8}>
                <RelatedArticles
                  vehicle={vehicle}
                  hasReviewContent={!!get(editorialReview, 'reviewLink')}
                  usePreProdContent
                />
              </Col>
            </Row>
          </Container>
        </ScrollElement>
      )}

      {!isPreprod && (
        <Container>
          <Row>
            <Col xs={12} lg={8}>
              <FaqSection vehicle={vehicle} params={params} />
              <hr className="mt-1 mb-2 mb-md-3_5" />
            </Col>
          </Row>
        </Container>
      )}
      <Container>
        <FeatureFlag name="core-breadcrumbs">
          {enabled => (
            <CoreBreadcrumbs
              className="pl-0 mb-2"
              vehicle={vehicle}
              breadcrumbsParams={{
                params: propsParams,
                isMainCorePage: true,
                includeLastLink: true,
                isSeot3630Enabled: enabled && isSeot3630Enabled,
                seot3630Submodel: enabled ? seot3630ChalSubmodelType : null,
              }}
              creativeId="breadcrumb"
            />
          )}
        </FeatureFlag>
        <PageLinksWidget divideSections={false} title={`Related ${displayVehicle} info`} className="mb-3_5" />
      </Container>
      {!isPreprod && isMobile && (
        <DockedSrpLink
          params={params}
          showElementSelector="[data-tracking-parent='edm-entry-review-scorecard2'],.core-also-viewed-vehicles"
          inventoryTotalNumber={inventoryCount?.total}
          trackingId="docked_srp_button"
          creativeId="new_core_dock_bottom"
        />
      )}
      {!isPreprod && (
        <Experiment name="core-6334-year-type-label" showDefault>
          <Recipe name="ctrl" isDefault />
          <Recipe name="chal" />
        </Experiment>
      )}
      {params.isPluginHybrid && (
        <ClientSideRender>
          <AppointmentSurveyExperiment experimentName="core-5931-siteintercept" />
        </ClientSideRender>
      )}
    </main>
  );
}

NewCoreUI.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear,
  editorialReview: EditorialReviewEntities.EditorialReview,
  firstContent: FirstContentEntity,
  visitorLocation: VisitorEntities.Location,
  styleAttributes: PropTypes.objectOf(PropTypes.bool),
  isMobile: PropTypes.bool,
  inventoryCount: InventoryAggregateEntities.InventoryCountByType,
  isMinimalViableData: PropTypes.bool,
  isSeot3854Enabled: PropTypes.bool,
  isSeot3630: PropTypes.bool,
  isCore6112: PropTypes.bool,
  isCore6334Enabled: PropTypes.bool,
};

NewCoreUI.defaultProps = {
  vehicle: null,
  editorialReview: null,
  firstContent: null,
  visitorLocation: {},
  styleAttributes: null,
  isMobile: false,
  inventoryCount: null,
  isMinimalViableData: false,
  isSeot3854Enabled: false,
  isSeot3630: false,
  isCore6112: false,
  isCore6334Enabled: false,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
  isCore6334Enabled:
    ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'core-6334-year-type-label',
      defaultVal: 'ctrl',
    }) === 'chal',
});

/*
 * Please keep these binds to a minimum and only to page-level binds.
 * Component-level binds (binds needed by components) should be done
 * within the components themselves. This also ensures components are
 * completely standalone and reusable.
 */
export const NewCore = connect(mapStateToProps)(
  connectToModel(NewCoreUI, {
    isMinimalViableData: bindToPath(
      ({ vehicle }) => vehicle && buildLatestVehiclesPath(getParamsFromVehicle(vehicle)),
      VehicleModel,
      (latestVehicles, { vehicle }) =>
        get(latestVehicles.find(({ year }) => year === vehicle.year), 'isMinimalViableData')
    ),
    visitorLocation: bindToPath('location', VisitorModel),
    editorialReview: bindToPath(
      ({ params, vehicle }) =>
        vehicle && buildReviewPath({ ...getParamsFromVehicle(vehicle), submodel: params.submodel }),
      EditorialReviewModel
    ),
    firstContent: bindToPath(
      ({ vehicle }) => vehicle && buildPreProdContentPath(getParamsFromVehicle(vehicle)),
      EditorialReviewModel
    ),
    styleAttributes: bindToPath(
      ({ vehicle }) => vehicle && FeatureSpecsPaths.buildPartialFeaturesPath(getParamsFromVehicle(vehicle)),
      VehicleModel,
      styles => get(styles, '[0].styleAttributes')
    ),
    inventoryCount: bindToPath(
      ({ vehicle }) =>
        vehicle &&
        buildInventoryCountByTypePath({
          ...getParamsFromVehicle(vehicle),
          year: isPreProd(vehicle.pubStates) || DEALERLESS_OEM.includes(vehicle.make.slug) ? undefined : vehicle.year,
        }),
      InventoryAggregateModel
    ),
    isSeot3854Enabled: bindToPath(
      'seoExperiments',
      SEOModel,
      seoExperiments => seoExperiments && seoExperiments.includes('first_impressions_removal')
    ),
    isSeot3630: bindToPath(
      'seoExperiments',
      SEOModel,
      seoExperiments => seoExperiments && seoExperiments.includes('core_type_breadcrumbs')
    ),
    isCore6112: bindToPath(
      'seoExperiments',
      SEOModel,
      seoExperiments => seoExperiments && seoExperiments.includes('trim_comparison_link')
    ),
  })
);

NewCore.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear,
};
